import { FETCH_VI_DATA_ERROR, FETCH_VI_DATA_SUCCESS, FETCH_VI_IMAGE_LOADER } from "../../actions/vehicleImageTypes";
const initialState = {
    vehicleImageData: "",
    vehicleImageError: null,
    vehicleImageLoader: true,
};
const vehicleImageDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_VI_DATA_SUCCESS:
            return {
                ...state,
                vehicleImageData: action.payload,
                vehicleImageError: null,
                vehicleImageLoader: false,
            };
        case FETCH_VI_DATA_ERROR:
            return {
                ...state,
                vehicleImageData: null,
                vehicleImageError: action.payload,
                vehicleImageLoader: false,
            };
        case FETCH_VI_IMAGE_LOADER:
            return {
                ...state,
                vehicleImageLoader: true,
            };
        default:
            return state;
    }
};
export default vehicleImageDataReducer;
